import { convertUTCToLocal } from '../UTILS'

const normalFont = {
    lineHeight: 'normal',
    paddingTop: '10px',
    paddingBottom: '10px'
}

const boldFont = {
    fontWeight: 'bold'
}

export const CHANGELOG_DATA = [
    {
        field: 'version',
        headerName: 'Version',
        cellStyle: boldFont,
        rowGroup: true,
        hide: true,
        rowGroupCellStyle: boldFont
    },
    {
        field: 'createdDate',
        headerName: 'Date',
        width: 150,
        valueGetter: (item: any) =>
            item?.data?.createdDate
                ? convertUTCToLocal(item?.data?.createdDate, 'YYYY-MM-DD HH:mm')
                : '',
        xsort: 'desc',
        cellStyle: normalFont
    },
    {
        field: 'title',
        headerName: 'Title',
        flex: 2,
        minWidth: 200,
        cellStyle: normalFont
    },
    {
        field: 'description',
        headerName: 'Description',
        flex: 2,
        minWidth: 200,
        cellStyle: normalFont
    },
    {
        field: 'createdBy',
        headerName: 'Created By',
        width: 120,
        cellStyle: normalFont
    },
    {
        field: 'lastModifiedBy',
        headerName: 'Modified By',
        width: 120,
        cellStyle: normalFont
    },
    {
        headerName: 'Actions',
        field: 'actions',
        cellRenderer: 'actionCellRenderer',
        width: 90,
        sortable: false,
        filter: false,
        resizable: false,
        cellStyle: normalFont
    }
]

export const CHANGELOG_DATA_NOACTION = [
    {
        field: 'version',
        headerName: 'Version',
        cellStyle: boldFont,
        rowGroup: true,
        hide: true,
        rowGroupCellStyle: boldFont
    },
    {
        field: 'createdDate',
        headerName: 'Date',
        width: 150,
        valueGetter: (item: any) =>
            item?.data?.createdDate
                ? convertUTCToLocal(item?.data?.createdDate, 'YYYY-MM-DD HH:mm')
                : '',
        xsort: 'desc',
        cellStyle: normalFont
    },
    {
        field: 'title',
        headerName: 'Title',
        flex: 2,
        minWidth: 200,
        cellStyle: normalFont
    },
    {
        field: 'description',
        headerName: 'Description',
        flex: 2,
        minWidth: 200,
        cellStyle: normalFont
    },
    {
        field: 'createdBy',
        headerName: 'Created By',
        width: 120,
        cellStyle: normalFont
    },
    {
        field: 'lastModifiedBy',
        headerName: 'Modified By',
        width: 120,
        cellStyle: normalFont
    }
]
