import React, { useEffect, useState } from 'react'
import Modal from '../../atoms/Modal'
import LoadingSpinner from '../../atoms/LoadingComponent/LoadingSpinner'
import { _getGridData } from 'services/API/_changeLog'
import ChangeLogGrid from './ChangeLogGrid'
import { useAccount, useMsal } from '@azure/msal-react'

interface IChangeLogModalViewProps {
    panelOpen: boolean
    setPanelOpen: any
    onDismiss: any
}
const ChangeLogGridModelView: React.FC<IChangeLogModalViewProps> = (props): React.ReactElement => {
    const { accounts } = useMsal()
    const account: any = useAccount(accounts[0])
    const roles = account ? account?.idTokenClaims?.roles : []
    const { panelOpen, setPanelOpen, onDismiss } = props

    const [data, setData] = useState<any>()
    const [loader, setLoader] = useState(false)

    async function getGridData() {
        await _getGridData(setLoader, setData)
    }
    useEffect(() => {
        if (panelOpen) {
            getGridData()
        }
    }, [panelOpen])

    return (
        <Modal isModalOpen={panelOpen} onDismiss={onDismiss} width='77.5rem' title='Changelog'>
            {loader ? (
                <LoadingSpinner />
            ) : (
                <>
                    <ChangeLogGrid
                        data={data}
                        setPanelOpen={setPanelOpen}
                        panelOpen={panelOpen}
                        selectedData={{}}
                        refreshGridData={getGridData}
                        roles={roles}
                        hideActions={true}
                    />
                </>
            )}
        </Modal>
    )
}

export default ChangeLogGridModelView
