import React from 'react'
import { Editor, EditorState } from 'react-draft-wysiwyg'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { DraftJsToolbarWithAllOptions } from '../../../utils/UTILS'

interface IDraftEditor {
    editorState: EditorState
    setEditorState?: any
    readOnly?: boolean
    staticContentNoStyle?: boolean
    toolbarHidden?: boolean
    toolbar?: any
}

const DraftEditor = (props: IDraftEditor) => {
    const {
        editorState,
        setEditorState,
        readOnly = false,
        staticContentNoStyle = false,
        toolbarHidden = false,
        toolbar = DraftJsToolbarWithAllOptions
    } = props

    return (
        <div className='draft-editor' data-cy='draft-editor'>
            <Editor
                editorState={editorState}
                onEditorStateChange={setEditorState}
                wrapperClassName={staticContentNoStyle ? '' : 'wrapper-class'}
                editorClassName={staticContentNoStyle ? '' : 'editor-class'}
                toolbarClassName='toolbar-class'
                toolbar={toolbar}
                toolbarHidden={toolbarHidden}
                readOnly={readOnly}
            />
        </div>
    )
}

export default DraftEditor
