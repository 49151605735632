import React, { useEffect, useState } from 'react'
import { TextField } from '@fluentui/react/lib/TextField'
import { styles } from './SimpleTextBoxStyles'
import { runValidation } from '../../../utils/VALIDATIONS'
import { REQUIRED_FIELD } from '../../../utils/ERRORMESSAGES'
import { ITextStyles } from '@fluentui/react'
export interface SimpleTextboxProps {
    onChange?: any
    multiline?: boolean
    resizable?: boolean
    label?: string
    className?: string
    regex?: any
    description?: string
    placeholder?: string
    borderless?: boolean
    invalid?: boolean
    disabled?: boolean
    readOnly?: boolean
    errorMessage?: string
    customError?: string
    value?: string | undefined
    isRequired?: boolean
    maxLength?: number
    minLength?: number
    minVal?: number
    maxVal?: number
    gapOf?: number
    inputClassName?: string
    onBlur?: any
}
const SimpleTextbox: React.FC<SimpleTextboxProps> = (props) => {
    const { root } = styles

    const textStyles: Partial<ITextStyles> = {
        root: { width: '-webkit-fill-available' }
    }
    const {
        onChange,
        placeholder,
        borderless = false,
        disabled = false,
        invalid = false,
        readOnly = false,
        multiline = false,
        resizable = false,
        errorMessage = '',
        customError = '',
        value = '',
        label = '',
        description = '',
        regex,
        isRequired = false,
        maxLength,
        minLength,
        minVal,
        maxVal,
        gapOf,
        inputClassName = '',
        onBlur
    } = props

    const [Errors, setErrors] = useState('')

    useEffect(() => {
        if (isRequired && !value) {
            setErrors(REQUIRED_FIELD)
        } else if (isRequired && value) {
            setErrors('')
        }
        const errorList = runValidation({
            value,
            regex,
            isRequired,
            minLength,
            maxLength,
            minVal,
            maxVal,
            gapOf,
            errorMessage
        })
        setErrors(errorList.join(', '))
    }, [value, isRequired])

    const onChangeTextField = (value: string) => {
        onChange(value)
    }
    return (
        <div className={root}>
            <TextField
                label={label}
                borderless={borderless}
                onChange={(newValue: any) => onChangeTextField(newValue.target.value)}
                validateOnFocusOut
                errorMessage={Errors.length > 0 ? Errors : customError}
                placeholder={placeholder}
                readOnly={readOnly}
                disabled={disabled}
                multiline={multiline}
                resizable={resizable}
                description={description}
                invalid={!!(invalid || Errors.length > 0) || customError.length > 0}
                value={value}
                styles={textStyles}
                inputClassName={inputClassName}
                onBlur={onBlur}
            />
        </div>
    )
}
export default SimpleTextbox
