import { mergeStyleSets } from '@fluentui/react/lib/Styling'

export const styles = mergeStyleSets({
    root: {
        display: 'flex',
        alignItems: 'baseline'
    },

    simpleTextbox: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '0.5rem',
        color: '#201f1e',
        height: '30px',
        background: '#ffffff',
        boxSizing: 'border-box',
        borderRadius: '2px',
        width: '-webkit-fill-available'
    }
})
