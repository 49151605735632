import { MessageBarType } from '@fluentui/react'
import { ApiService } from 'services/apiUtils'
import {
    BASE_URL,
    GET_CHANGELOG,
    GET_VERSION_LATEST,
    PUT_CHANGELOG,
    POST_CHANGELOG,
    DELETE_CHANGELOG
} from 'utils/ENDPOINTS'
import { LOG_MESSAGES } from 'utils/ERRORMESSAGES'

export const _getGridData = async (setLoader: any, setData: any) => {
    setLoader(true)
    const pathName = `${BASE_URL}${GET_CHANGELOG}`
    const requestObj = {
        path: pathName
    }
    try {
        await ApiService(requestObj).then(async (res: { data: any }) => {
            setLoader(false)
            return setData(res.data)
        })
    } catch (error) {
        setData([])
        setLoader(false)
    }
}

export const _getLatestVersion = async (setLoader: any, setData: any) => {
    setLoader(true)
    const pathName = `${BASE_URL}${GET_VERSION_LATEST}`
    const requestObj = {
        path: pathName
    }
    try {
        await ApiService(requestObj).then(async (res: { data: any }) => {
            setLoader(false)
            return setData(res.data)
        })
    } catch (error) {
        setData([])
        setLoader(false)
    }
}

export const _onSave = async (
    setloader: any,
    editMode: any,
    title: any,
    description: any,
    version: any,
    selectedDate: any,
    selectedData: any,
    refreshGridData: () => void,
    onDismiss: any,
    setNotifications: any
) => {
    setloader(true)
    const pathName = editMode ? `${BASE_URL}${PUT_CHANGELOG}` : `${BASE_URL}${POST_CHANGELOG}`
    const properties: any = {
        title: title,
        description: description,
        version: version,
        createdDate: selectedDate
    }

    if (editMode) {
        properties.id = selectedData?.id
    }

    const requestObj: any = {
        path: pathName,
        method: editMode ? 'PUT' : 'POST',
        payload: properties
    }

    await ApiService(requestObj)
        .then((res: any) => {
            setNotifications(
                editMode ? LOG_MESSAGES.UPDATE_SUCCESS_MSG : LOG_MESSAGES.ADD_SUCCESS_MSG,
                MessageBarType.success
            )
            onDismiss()
            refreshGridData()

            return res.data
        })
        .catch(() => {
            setNotifications(
                editMode ? LOG_MESSAGES.UPDATE_ERROR_MSG : LOG_MESSAGES.ADD_ERROR_MSG,
                MessageBarType.error
            )
        })

    setloader(false)
}
export const _deleteData = async (
    data: any,
    setSelectedData: any,
    refreshGridData: any,
    setNotifications: any,
    setshowDelete: any
) => {
    const pathName = `${BASE_URL}${DELETE_CHANGELOG}/${data.id}`
    const requestObj = {
        path: pathName,
        method: 'DELETE'
    }

    await ApiService(requestObj)
        .then((res: any) => {
            setSelectedData('')
            refreshGridData()
            setNotifications(LOG_MESSAGES.DELETE_SUCCESS_MSG, MessageBarType.success)
            return res.data
        })
        .catch(() => {
            setNotifications(LOG_MESSAGES.DELETE_ERROR_MSG, MessageBarType.error)
        })

    setshowDelete(false)
}
