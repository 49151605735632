import React, { useState } from 'react'
import Grid from '../../atoms/Grid/Grid'
import { Stack, Icon, IStackTokens } from '@fluentui/react'
import ConfirmDialog from '../../atoms/ConfirmDialog'
import { DELETE_CONFIRM } from '../../../utils/ERRORMESSAGES'
import { ROLES } from '../../../utils/CONSTANTS'
import { NotificationContext } from '../../../context/NotificationContext'
import { _deleteData } from '../../../services/API/_changeLog'
import { CHANGELOG_DATA, CHANGELOG_DATA_NOACTION } from 'utils/DATA_COLUMNS/CHANGELOG'

const outerStackTokens: IStackTokens = { childrenGap: 10 }

interface ChangeLogGridProps {
    data: any
    setPanelOpen: any
    setSelectedData?: any
    panelOpen: boolean
    selectedData?: any
    refreshGridData?: any
    roles?: Array<string | null | undefined>
    hideActions?: boolean
}

const GridactionBtns = (
    item: any,
    setPanelOpen: any,
    setSelectedData: any,
    setshowDelete: any,
    roles: any
) => {
    function editActionBtn(data: any) {
        setPanelOpen(true)
        setSelectedData(data)
    }

    function deleteActionBtn(data: any) {
        setshowDelete(true)
        setSelectedData(data)
    }

    return (
        <div className='actionBtnCellrendrer'>
            <Stack horizontal tokens={outerStackTokens}>
                {roles && (roles.includes(ROLES.ADMIN) || roles.includes(ROLES.SUPERADMIN)) ? (
                    <>
                        <Stack.Item>
                            <Icon
                                iconName='Edit'
                                data-action='editAttribute'
                                onClick={() => editActionBtn(item)}
                            />
                        </Stack.Item>

                        <Stack.Item>
                            <Icon
                                iconName='Delete'
                                data-action='deleteAttribute'
                                onClick={() => deleteActionBtn(item)}
                            />
                        </Stack.Item>
                    </>
                ) : (
                    <></>
                )}
            </Stack>
        </div>
    )
}

const ChangeLogGrid: React.FC<ChangeLogGridProps> = (props): React.ReactElement => {
    const { setNotifications } = React.useContext(NotificationContext)
    const {
        data,
        setPanelOpen,
        setSelectedData,
        selectedData,
        refreshGridData,
        roles,
        hideActions = false
    } = props
    const [showDelete, setshowDelete] = useState(false)
    const hideConfirmBox = () => {
        setshowDelete(false)
        setSelectedData('')
    }
    const confirmDelete = `${selectedData.title}, ${DELETE_CONFIRM}`

    const deleteData = async (data: any) => {
        await _deleteData(data, setSelectedData, refreshGridData, setNotifications, setshowDelete)
    }
    return (
        <>
            <Grid
                rowData={data}
                paginationPageSize={25}
                columnDefination={hideActions ? CHANGELOG_DATA_NOACTION : CHANGELOG_DATA}
                actionButtons={({ data }: any) =>
                    GridactionBtns(data, setPanelOpen, setSelectedData, setshowDelete, roles)
                }
                sideBar={false}
            />

            <ConfirmDialog
                isDialogVisible={showDelete}
                message={confirmDelete}
                confirm={() => deleteData(selectedData)}
                hideDialog={hideConfirmBox}
            />
        </>
    )
}

export default ChangeLogGrid
