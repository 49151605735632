import * as React from 'react'
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog'
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button'

interface dialogprops {
    isDialogVisible: boolean
    message: string
    title?: string
    confirm: () => void
    hideDialog: () => void
}

const modalPropsStyles = { main: { maxWidth: 450 } }

const DialogBoxComponent: React.FunctionComponent<dialogprops> = (props) => {
    const { isDialogVisible, message, confirm, hideDialog, title = 'Delete Confirmation' } = props
    const modalProps = React.useMemo(
        () => ({
            isBlocking: true,
            styles: modalPropsStyles
        }),
        []
    )
    const dialogContentProps = {
        type: DialogType.normal,
        title: title,
        subText: message
    }

    return (
        <>
            <Dialog
                hidden={!isDialogVisible}
                onDismiss={hideDialog}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >
                <DialogFooter>
                    <PrimaryButton onClick={confirm} text='Confirm' />
                    <DefaultButton onClick={hideDialog} text='Cancel' />
                </DialogFooter>
            </Dialog>
        </>
    )
}

export default DialogBoxComponent
